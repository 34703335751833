import { useMemo, useState } from 'react';
import { Input, Select } from '@formily/antd-v5';
import { Button, message, Modal } from 'antd';
import { omit } from 'lodash-es';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { getMenuList, deleteMenu } from 'src/api/clients/system';
import { QuerySceneEnum } from 'src/api/types/common';
import { IGetMenuListReq, IMenuItem } from 'src/api/types/system';
import { columns, schema } from './schema';
import { MenuModal } from './menu-modal';

const QueryTableMenusManage = 'SystemMenusManageTableName';

export default function SystemMenusManage() {
  const [visible, { setTrue: setShow, setFalse: setHide }] = useBoolean(false);
  const [currentMenu, setCurrentMenu] = useState<IMenuItem>();
  const [menuList, setMenuList] = useState<IMenuItem[]>([]);
  const getMenuAll = async (data: IGetMenuListReq) => {
    const params = omit(data, ['offset', 'limit']);
    const res = await getMenuList({ scene: QuerySceneEnum.TREE, ...params });
    setMenuList(res);
    return {
      data: res,
      total: res.length,
    };
  };
  const onRefresh = useMemoizedFn(() => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload: {},
      },
      QueryTableMenusManage,
    );
  });

  const onDeleteMenu = useMemoizedFn((col: IMenuItem) => {
    const { menuId, menuName } = col || {};
    if (!menuId) return message.error('删除失败，请刷新重试');
    Modal.confirm({
      title: '删除菜单',
      content: `删除后无法恢复，确定删除 ${menuName} 菜单吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await deleteMenu(menuId);
        if (result) {
          onRefresh();
          message.success('删除成功');
        } else {
          message.error('删除失败，请刷新重试');
        }
      },
    });
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 100,
        renderType: 'Operation',
        renderProps: (col: IMenuItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setCurrentMenu(col);
                  setShow();
                },
              },
              {
                label: '删除',
                onClick: () => {
                  onDeleteMenu(col);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <div className="m-4 p-6 bg-white rounded-md">
      <QueryTable.Provider name={QueryTableMenusManage} resultKey="data" requestFn={getMenuAll}>
        <QueryTable.Filter schema={schema} components={{ Input, Select }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="menuId"
          scroll={{ x: 1200 }}
          tabsNode={
            <Button type="primary" onClick={setShow}>
              添加菜单
            </Button>
          }
        />
      </QueryTable.Provider>
      <MenuModal
        data={currentMenu}
        open={visible}
        tree={menuList}
        onOk={onRefresh}
        onCancel={() => {
          setHide();
          setCurrentMenu(undefined);
        }}
      />
    </div>
  );
}
