import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input, Select, Radio, NumberPicker, Switch, TreeSelect } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { InputIcon } from 'src/components/formily';
import { IMenuItem } from 'src/api/types/system';
import { updateMenu, createMenu } from 'src/api/clients/system';
import { Field, onFieldInputValueChange, onFormMount } from '@formily/core';
import { ISelectTree, MenuTypeEnum, VisibleEnum } from 'src/interface';
import { operationSchema } from './schema';

interface IProps extends ModalProps {
  data?: IMenuItem;
  tree: IMenuItem[];
}

export function MenuModal(props: IProps) {
  const { open, onOk, onCancel, data, tree, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.menuId;

  const menuTree = useMemo(() => {
    if (!tree) return [];
    const transformData = (data: IMenuItem): ISelectTree | null => {
      if (data.menuType === MenuTypeEnum.BUTTON) return null;
      return {
        value: Number(data?.menuId),
        label: data.menuName ?? '',
        children: data.children?.map(transformData).filter(Boolean) as ISelectTree[],
      };
    };
    return tree.map((item) => transformData(item));
  }, [tree]);

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<Omit<IMenuItem, 'visible'> & { visible: boolean }>();
    const { visible, ...rest } = values ?? {};
    const bodyData = {
      ...rest,
      visible: visible ? VisibleEnum.SHOW : VisibleEnum.HIDE,
    };
    if (isEdit) {
      bodyData.menuId = data?.menuId;
    }
    setTrue();
    const request = isEdit ? updateMenu : createMenu;
    try {
      const res = await request(bodyData as IMenuItem);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle(e);
        onOk?.(e);
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
        onFieldInputValueChange('menuType', (field) => {
          const value = (field as Field).value;
          const parentIdField = field.query('.parentId').take();
          const permissionField = field.query('.permission').take();
          if (parentIdField) parentIdField.visible = value !== MenuTypeEnum.DIRE;
          if (permissionField) {
            (permissionField as Field).validator =
              value === MenuTypeEnum.BUTTON ? [{ required: true, message: '请输入权限标识' }] : [];
            permissionField.visible = value !== MenuTypeEnum.DIRE;
          }
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted && open) {
      const formInstance = formRef.current?.getFormInstance();
      formInstance?.setFieldState('parentId', (field) => {
        if (field.componentProps) {
          field.componentProps.treeData = menuTree;
        }
      });
      formInstance?.setFieldState('menuType', (field) => {
        if (field.componentProps) {
          field.disabled = isEdit;
        }
      });
    }
  }, [menuTree, formMounted, open]);

  useEffect(() => {
    const formInstance = formRef.current?.getFormInstance();
    const { menuType, menuName, orderNum, parentId, path, permission, remark, target, icon } =
      data ?? {};
    formInstance?.setValues({
      menuType,
      menuName,
      orderNum,
      parentId,
      path,
      permission,
      remark,
      target,
      icon,
      visible: data?.visible === VisibleEnum.SHOW,
    });
  }, [menuTree, data]);

  return (
    <Modal
      open={open}
      title={isEdit ? '编辑菜单' : '新增菜单'}
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ Input, Select, Radio, NumberPicker, Switch, InputIcon, TreeSelect }}
        schema={operationSchema}
        formOptions={formOptions}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
